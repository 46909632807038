import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import pic01 from '../assets/images/Book2.jpg'
import pic02 from '../assets/images/Bernard-Lhermine.jpg'
import pic03 from '../assets/images/Book3.jpg'
import pic04 from '../assets/images/Book4.jpg'
import pic05 from '../assets/images/Book5.jpg'

class Homepage extends React.Component {
  render() {
    const siteTitle = "4HisGlory! - The Lord's Prayer Journal"
    const amazonLink = '/'

    return (
      <Layout amazonLink={amazonLink}>
        <Helmet title={siteTitle}>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-84G5H0DMPB"
          ></script>
          <script type="javascript">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);
            gtag('js', new Date()); gtag('config', 'G-84G5H0DMPB');
            `}
          </script>
        </Helmet>

        <section id="lords-prayer-journal" className="main style1">
          <div className="grid-wrapper">
            <div className="col-6">
              <header className="major">
                <h2>
                  Rekindle the power of the Lord's Prayer in your life with{' '}
                  <i>The Lord's Prayer Journal</i>
                </h2>
              </header>
              <p>
                <i>
                  <b>
                    The Lord’s Prayer Journal: Praying as God taught us to pray
                  </b>
                </i>{' '}
                helps you discover and experience the richness of the prayer
                that Jesus Christ Himself gave us. This book is your personal
                companion to sincerely journaling this prayer and deepening your
                relationship with God.
              </p>
              <ul className="actions uniform">
                <li>
                  <a href={amazonLink} className="button special">
                    Available on Amazon
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="col-6"
              onClick={() => {
                window.location.href = '/#features'
              }}
              onKeyDown={() => {
                window.location.href = '/#features'
              }}
              role="presentation"
            >
              <span className="image fit">
                <img
                  src={pic01}
                  alt="The Lord's Prayer Journal - example prayer pages"
                />
              </span>
            </div>
          </div>
        </section>

        <section id="features" className="main style2">
          <div className="grid-wrapper">
            <div className="col-6">
              <span className="image fit">
                <img
                  src={pic05}
                  alt="The Lord's Prayer Journal - explanation pages"
                />
              </span>
            </div>
            <div className="col-6 mid">
              <h2>
                A clear and personal explanation of each section of the Lord’s
                Prayer and how to get the most out of journaling it
              </h2>
            </div>

            <div className="col-6">
              <span className="image fit">
                <img
                  src={pic03}
                  alt="The Lord's Prayer Journal - journaling entry pages"
                />
              </span>
            </div>
            <div className="col-6 mid">
              <h2 style={{ marginBottom: '0.2em' }}>
                3 months worth of daily journal entries with an easy-to-follow
                structure to enrich your prayer life
              </h2>
              <ul>
                <li>Journal through each section of the Lord’s Prayer</li>
                <li>Lift up others in prayer</li>
                <li>Record and meditate on a verse for the day</li>
                <li>Thank God for specific answered prayers and blessings</li>
              </ul>
            </div>

            <div className="col-6">
              <span className="image fit">
                <img
                  src={pic04}
                  alt="The Lord's Prayer Journal - weekly reflection pages"
                />
              </span>
            </div>
            <div className="col-6 mid">
              <h2>
                Large weekly reflection pages for when you need more free-form,
                creative space
              </h2>
            </div>

            <div className="col-12 mid">
              <hr />
              <h2>Plus:</h2>
              <ul>
                <li>
                  A spiritual journey visualization that helps you summarize
                  your prayers and see the bigger picture of what God is doing
                  in your life
                </li>
                <li>
                  A reference template with examples and detailed journaling
                  prompts
                </li>
                <li>
                  Insights into common questions about how to pray for God’s
                  will, forgiving others, and avoiding journaling pitfalls that
                  could lead to a stagnant prayer life
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section id="testimonials" className="main style1 special">
          <div className="grid-wrapper testimonials">
            <div className="col-12">
              <header className="major">
                <h2>Testimonials</h2>
              </header>
            </div>

            <div className="col-4">
              <h3>
                “For me, this book has almost instantly opened up a new, deeper
                connection with God.”
              </h3>
              <h4>— Glen C.</h4>
            </div>
            <div className="col-4">
              <h3>
                “Journaling this way puts my heart and mind in the right posture
                before God.”
              </h3>
              <h4>— Chris C.</h4>
            </div>
            <div className="col-4">
              <h3>
                “As someone working through recovery, I have found this journal
                to be a very helpful tool for staying focused on God.”
              </h3>
              <h4>— Katie B.</h4>
            </div>
            <div className="col-4">
              <h3>
                “I’ve been a Christian a long time, and{' '}
                <i>The Lord's Prayer Journal</i> has enhanced my prayer life.”
              </h3>
              <h4>— Ernie M.</h4>
            </div>
            <div className="col-4">
              <h3>
                “I found that I wanted to spend a lot of time on the journaling,
                that I looked forward to it.”
              </h3>
              <h4>— Michelle B.</h4>
            </div>
            <div className="col-4">
              <h3>
                “It is a great resource to have in the disciple's toolbox.”
              </h3>
              <h4>— James M.</h4>
            </div>
          </div>
        </section>

        <section id="feedback" className="main style2 special">
          <div className="container">
            <header className="major">
              <h2>Share your feedback</h2>
            </header>
            <p>
              My mission in creating this book is to rekindle the power of the
              Lord’s Prayer in our lives for His glory. I would love to hear
              about your experience with this book and any feedback you have to
              help further this mission.
            </p>
            <ul className="actions uniform">
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSetBFYTeATjITB75ubJ4Qnvu-5dyCG52xX3Ss4up60iYv9LEg/viewform?usp=sf_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button special"
                >
                  Give Feedback
                </a>
              </li>
            </ul>
          </div>
        </section>

        <section id="author" className="main style1">
          <div className="grid-wrapper-small">
            <div className="col-4">
              <span className="image fit">
                <img src={pic02} alt="Bernard Lherminé" />
              </span>
            </div>
            <div className="col-8 mid">
              <header className="major">
                <h2>Bernard Lherminé</h2>
                <small>
                  Author of <i>The Lord's Prayer Journal</i>
                </small>
                <br />
              </header>
              <p>
                I was Born in Ivory Coast and was raised in France before I came
                to the USA as a foreign exchange student in 1984. I am a sinner,
                saved by His grace at age 33, seeking to grow closer to Him,
                even as I stumble on my way Home.{' '}
              </p>
              <p>
                Since 2012, journaling the Lord's Prayer has been a fundamental
                part of my walk with Jesus, how I experience God, and how I keep
                in step with the Spirit. 4HisGlory! is a tagline I have been
                using for years on notepads, emails, and my journal to remind
                myself and others of our reason for living.
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Homepage
