import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <section id="footer">
        <h2
          onClick={() => {
            window.location.href = '/#'
          }}
          onKeyDown={() => {
            window.location.href = '/#'
          }}
          role="presentation"
        >
          May the Lord use this journal to bless you and bring Him glory.
        </h2>
        <ul className="copyright">
          <li>&copy; 4HisGlory!</li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href={this.props.amazonLink}>Amazon Product Page</a>
          </li>
        </ul>
      </section>
    )
  }
}

export default Footer
