import React from 'react'

//import pic01 from '../assets/images/Logo-tm-white.png'

class Header extends React.Component {
  render() {
    return (
      <section
        id="header"
        onClick={() => {
          window.location.href = '/#lords-prayer-journal'
        }}
        onKeyDown={() => {
          window.location.href = '/#lords-prayer-journal'
        }}
        role="presentation"
      >
        <div className="inner"></div>
      </section>
    )
  }
}

export default Header
