import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout hideHeader={true}>
    <div class="main">
      <h1>Welcome to our Privacy Policy</h1>
      <h3>Your privacy is important to us.</h3>
      <p>
        It is 4HisGlory!'s policy to respect your privacy regarding any
        information we may collect while operating our website. This Privacy
        Policy applies to <a href="/">https://4hisglory.live</a> (hereinafter,
        "us", "we", or "https://4hisglory.live"). We respect your privacy and
        are committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms and conditions posted on
        our Website, set forth the general rules and policies governing your use
        of our Website. Depending on your activities when visiting our Website,
        you may be required to agree to additional terms and conditions.
      </p>
      <h2>Website Visitors</h2>
      <p>
        Like most website operators, 4HisGlory! collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. 4HisGlory!'s purpose in collecting non-personally identifying
        information is to better understand how 4HisGlory!'s visitors use its
        website. From time to time, 4HisGlory! may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>
      <p>
        4HisGlory! also collects potentially personally-identifying information
        like Internet Protocol (IP) addresses for logged in users and for users
        leaving comments on <a href="/">https://4hisglory.live</a> blog posts.
        4HisGlory! only discloses logged in user and commenter IP addresses
        under the same circumstances that it uses and discloses
        personally-identifying information as described below.
      </p>
      <h2>Gathering of Personally-Identifying Information</h2>
      <p>
        Certain visitors to 4HisGlory!'s websites choose to interact with
        4HisGlory! in ways that require 4HisGlory! to gather
        personally-identifying information. The amount and type of information
        that 4HisGlory! gathers depends on the nature of the interaction. For
        example, we ask visitors who provide feedback at
        <a href="/feedback">https://4hisglory.live/feedback</a> to provide a
        name and email address.
      </p>
      <h2>Security</h2>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet, or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>
      <h2>Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        and terms and conditions of every site you visit.
      </p>
      <p>
        We have no control over, and assume no responsibility for the content,
        privacy policies or practices of any third party sites, products or
        services.
      </p>
      <h2>Aggregated Statistics</h2>
      <p>
        4HisGlory! may collect statistics about the behavior of visitors to its
        website. 4HisGlory! may display this information publicly or provide it
        to others. However, 4HisGlory! does not disclose your
        personally-identifying information.
      </p>
      <p>
        Specifically, Google Analytics (with anonymization enabled) is used for
        analyzing how visitors interact with the website. Google Analytics uses
        cookies (discussed below). In addition to blocking cookies, you may
        prevent the collection of the data generated by the cookie and related
        to your use of the website (including your IP address) by Google as well
        as the processing of this data by Google by downloading and installing
        the browser plug-in available under the following link:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
          https://tools.google.com/dlpage/gaoptout?hl=en
        </a>{' '}
        Further information and Google‘s applicable privacy regulations can be
        found at{' '}
        <a href="https://policies.google.com/privacy?hl=en.">
          https://policies.google.com/privacy?hl=en
        </a>
        .
      </p>
      <h2>Affiliate Disclosure</h2>
      <p>
        This site may use affiliate links to earn a commission from certain
        links. This does not affect your purchases or the price you may pay.
      </p>
      <h2>Cookies</h2>
      <p>
        To enrich and perfect your online experience, 4HisGlory! uses "Cookies",
        similar technologies and services provided by others to display
        personalized content, appropriate advertising and store your preferences
        on your computer.
      </p>
      <p>
        A cookie is a string of information that a website stores on a visitor's
        computer, and that the visitor's browser provides to the website each
        time the visitor returns. 4HisGlory! uses cookies to help 4HisGlory!
        identify and track visitors, their usage of{' '}
        <a href="/">https://4hisglory.live</a>, and their website access
        preferences. 4HisGlory! visitors who do not wish to have cookies placed
        on their computers should set their browsers to refuse cookies before
        using 4HisGlory!'s websites, with the drawback that certain features of
        4HisGlory!'s websites may not function properly without the aid of
        cookies.
      </p>
      <p>
        By continuing to navigate our website without changing your cookie
        settings, you hereby acknowledge and agree to 4HisGlory!'s use of
        cookies.
      </p>
      <h2>Business Transfers</h2>
      <p>
        If 4HisGlory!, or substantially all of its assets, were acquired, or in
        the unlikely event that 4HisGlory! goes out of business or enters
        bankruptcy, user information would be one of the assets that is
        transferred or acquired by a third party. You acknowledge that such
        transfers may occur, and that any acquirer of 4HisGlory! may continue to
        use your personal information as set forth in this policy.
      </p>
      <h2>Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, 4HisGlory! may change its
        Privacy Policy from time to time, and in 4HisGlory!'s sole discretion.
        4HisGlory! encourages visitors to frequently check this page for any
        changes to its Privacy Policy. Your continued use of this site after any
        change in this Privacy Policy will constitute your acceptance of such
        change.
      </p>
      <h2>Contact Information</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        via <a href="mailto:privacy@4hisglory.live">email</a>.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
