import React from 'react'
import styled from 'styled-components'

import logo from '../../assets/images/Logo-tm-white.png'

const Brand = () => {
  return (
    <Image
      onClick={() => {
        window.location.href = '/#'
      }}
      src={logo}
      alt="4HisGlory! Logo"
      tabIndex="0"
      onKeyDown={() => {
        window.location.href = '/#'
      }}
      role="img"
    />
  )
}

export default Brand

const Image = styled.img`
  height: 65%;
  margin: auto 0;
  cursor: pointer;
`
